<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="data"
        sort-by="created_at"
        :sort-desc="true"
        :must-sort="true"
        :search="search"
        class="elevation-1"
        style="font-size: x-large"
        :loading="loading"
        loading-text="Загрузка..."
        @click:row="open"
        :items-per-page="20"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="display-1">{{title}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="flex-grow-1"></div>
            <v-text-field
             v-model="search"
             append-icon="mdi-magnify"
             label="Поиск"
             single-line
             hide-details
             ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.equipment.slug="{ item }">
          {{ item.equipment ? item.equipment.slug : '-' }}
        </template>
        <template v-slot:item.equipment.name="{ item }">
          <div class="word-break-all">{{ item.equipment ? item.equipment.name : '-' }}</div>
        </template>
        <template v-slot:item.client.name="{ item }">
          <div class="word-break-all">{{ item.client ? item.client.name : '-' }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div style="white-space: nowrap;">{{ item.created_date }}</div>
        </template>
        <template v-slot:item.status_id="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark>{{ item.status.name }}</v-chip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Обновить</v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2" v-show="pageCount > 1">
        <v-pagination 
          v-model="page" 
          :length="pageCount"
          :total-visible="12"
        >
        </v-pagination>
      </div>
    </v-card-text>

    <v-card-text>
      <v-btn
        rounded
        dark
        fixed
        bottom
        right
        x-large
        color="grey darken-2"
        style="z-index: 5; bottom: 50px;"
        v-if="!userIsKeeper"
        @click="create"
      >
        <v-icon class="mr-5">mdi-plus</v-icon>
        Дефектная ведомость
      </v-btn>
    </v-card-text>

    <ErrorDialog
      v-model="dialogErr"
      title="Ошибка"
      text="В процессе обработки данных произошла ошибка. Проверьте работу сети и повторите попытку позже."
      >
    </ErrorDialog>

  </v-card>
</template>

<script>
  import ErrorDialog from '@/components/ErrorDialog'

  export default {
    components: {
      ErrorDialog
    },
    data: () => ({
      title: 'Дефектные ведомости',
      modname: 'statements',
      search: '',
      page: 1,
      pageCount: 0,
      loading: true,
      dialogErr: false,
      headers: [
        { text: 'Номер', align: 'left', sortable: true, value: 'equipment.slug'},
        { text: 'Оборудование', align: 'left', sortable: true, value: 'equipment.name'},
        { text: 'Клиент', align: 'left', sortable: true, value: 'client.name'},
        { text: 'Дата', align: 'left', sortable: true, value: 'created_at'},
        { text: 'Статус', align: 'right', sortable: true, value: 'status_id'},
      ],
      data: [],
    }),

    created () {
      this.initialize()
      setInterval(() => {
        if (this.$route.name === 'statement-list') {
          this.initialize()
        }
      }, 1000 * 60 * 2)
    },

    methods: {
      initialize () {
        this.$axios.get(this.modname)
          .then((r) => {
            this.data = r.data;
          })
          .catch(error => {
            this.crudError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },

      create () {
        this.$axios.post(this.modname)
          .then((r) => {
            if (r.data.id) {
              this.$router.push('/statement/' + r.data.id)
            } else {
              this.crudError(r)
            }
          })
          .catch(error => {
            this.crudError(error)
          })
          .finally(() => {
            this.loading = false
          })
      },

      open (row) {
        this.$router.push('/statement/' + row.id)
      },

      crudError (error) {
        // eslint-disable-next-line
        console.error('Statement update error', error, this.data)
        this.openError()
      },

      openError () {
        this.dialogErr = !this.dialogErr
      },

    },
  }
</script>

<style>
  .v-data-table td {
    font-size: medium;
  }
  .word-break-all {
    word-break: break-all;
  }
  .v-data-table thead th,
  .v-data-table tbody td {
    padding-left: 8px;
    padding-right: 8px;
  }
  .v-data-table tbody td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  /*@media only screen and (orientation: portrait) {
    .v-data-table tbody td .ellipsis-text{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 170px;
    }
  }
  @media only screen and (orientation: landscape) {
    .v-data-table tbody td .ellipsis-text{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 300px;
    }
  }*/
</style>